body {
  margin: 0;
  a {
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      opacity: 0.6;
    }
  }
}

.Slot {
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 6px;
  padding: 16px;
  background-color: #eeeeee;
  margin-bottom: 12px;
  font-size: 17.5px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;

  &.selected {
    background-color: #4eb989;
    color: white;
  }

  @media (max-width: 600px) {
    font-size: 15px;
  }
}

.AvailableSlotList {
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  max-height: 60px;
  overflow: hidden;

  &.expanded {
    max-height: calc(100vh - 360px);
    overflow: scroll;
  }
}

.FlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header {
  height: 80px;
  width: 100%;
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  @media (max-width: 600px) {
    justify-content: center;
  }
}

.Footer {
  background-color: #4eb989;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  .Footer_col {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  p {
    color: white;
    font-size: 13px;
    margin: 0 20px;
  }
  @media (max-width: 600px) {
    padding: 12px 0;
    height: auto;
    flex-direction: column;
    .Footer_col {
      padding: 6px 0;
    }
    p {
      text-align: center;
    }
  }
}

.themeButton {
  background-color: transparent !important;
  color: #4eb989 !important;
  border: 2px solid #4eb989 !important;
}

.commonButton {
  border: 1px solid #888888 !important;
  background-color: transparent !important;
  border-radius: 4px !important;
  color: #555555 !important;
}

.lottieFrame {
  width: 200px;
  height: 200px;
  border-radius: 30px;
  border: none !important;
  outline: none !important;
}

.Wrap {
  display: flex;
  height: calc(100vh - 130px);
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
}

.LinkContent {
  height: calc(100vh - 130px);
  padding: 20px;
}

[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  // height: 300px;
  margin-top: 0px;
  max-height: 100vh;
  width: 100%;
}

.number-slide1 {
  background: rgb(64, 175, 255);
  background: linear-gradient(
    128deg,
    rgba(64, 175, 255, 1) 0%,
    rgba(63, 97, 255, 1) 100%
  );
}

.number-slide2 {
  background: rgb(255, 75, 64);
  background: linear-gradient(
    128deg,
    rgba(255, 154, 63, 1) 0%,
    rgba(255, 75, 64, 1) 100%
  );
}

.number-slide3 {
  background: rgb(182, 255, 64);
  background: linear-gradient(
    128deg,
    rgba(182, 255, 64, 1) 0%,
    rgba(63, 255, 71, 1) 100%
  );
  background: linear-gradient(
    128deg,
    rgba(189, 255, 83, 1) 0%,
    rgba(43, 250, 82, 1) 100%
  );
}

.number-slide4 {
  background: rgb(64, 255, 242);
  background: linear-gradient(
    128deg,
    rgba(64, 255, 242, 1) 0%,
    rgba(63, 188, 255, 1) 100%
  );
}

.number-slide5 {
  background: rgb(255, 64, 156);
  background: linear-gradient(
    128deg,
    rgba(255, 64, 156, 1) 0%,
    rgba(255, 63, 63, 1) 100%
  );
}

.number-slide6 {
  background: rgb(64, 76, 255);
  background: linear-gradient(
    128deg,
    rgba(64, 76, 255, 1) 0%,
    rgba(174, 63, 255, 1) 100%
  );
}

.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #4eb989;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #4eb989;
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: #999999;
}

iframe {
  display: none !important;
}

.keen-slider {
  width: 100vw !important;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

div[role="dialog"] {
  max-height: 96vh !important;
  margin-top: 2vh !important;
}
